@mixin font-size($sizeValue: 1.6, $lineHeight: 2.4) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
  line-height: ($lineHeight * 10) + px;
}

@mixin fontawesome {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}