@import 'miniGrid.scss';
@import './references/typography.scss';
@import './references/variables.scss';

.m-presets{
  padding:60px 0 20px;

  .wrapper{
    background:rgba(0,0,0,0.5);
    padding:25px;
    border-radius:20px;
  }

  h2{
    @include font-size(5, 6);
    text-align: center;
    margin-bottom:0;
  }

  p{
    text-align:center;
    max-width:800px;
    margin:0 auto;
  }

  .e-item{
    @include grid(12, 6, 6, 3, 3);
    margin-top:30px;

    .inner{
      overflow: hidden;
      border-radius: 10px;
      border:1px solid #111;
      position:relative;
      box-shadow:0 3px 8px rgba(0,0,0,0.5);

      .meta{
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        height:auto;
        font-weight:500;
        padding:10px 20px;
        display:flex;
        align-items: center;
        justify-content: flex-start;
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(8px);
        transition:background 1s ease-in-out;

        &.copied{
          background:#77dd77;
        }

        span{
          @include font-size(2, 3);
          font-family: $f-title;
          font-weight:600;
          text-transform: uppercase;
        }

        .tags{
          margin-left:auto;
          display:flex;

          .codeitem{
            width:40px;
            height:40px;
            margin-left:10px;
            padding:10px;
            border-radius:5px;
            transition:all .3s ease-in-out;

            &:hover{
              cursor: pointer;
              transform:scale(1.1);
            }

            &:active{
              transform:scale(0.5);
            }

            &.streamelements{
              background: #5088C7;

              &:hover {
                background: darken(#5088C7, 25%);
              }
            }

            &.streamlabs{
              background: darken(#80F5D2, 35%);

              &:hover {
                background: darken(#80F5D2, 45%);
              }
            }


            img{
              display:block;
              margin:0 auto;

            }
            .code{
              display:none;
            }
          }
        }
      }

      img{
        display:block;
        width:100%;
      }
    }
  }
}