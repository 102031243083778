.m-rangeslider{
  display:flex;
  height:34px;
  align-items: center;

  .e-title{
    white-space: nowrap;
    width:160px;
  }
  .e-slider{
    position:relative;
    margin-left:20px;
    width:100%;
    display:inline-flex;
    align-items: center;

    input[type=range] {
      height: 5px;
      -webkit-appearance: none;
      width:95%;
      margin-right:10px;
      background:none;
    }
    input[type=range]:focus {
      outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 10px;
      cursor: pointer;
      background:rgba(255,255,255,0.1);
      border-radius: 5px;
    }
    input[type=range]::-webkit-slider-thumb {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background: #FFFFFF;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -3px;
      box-shadow:0 0 5px rgba(0,0,0,0.5);
    }
    input[type=range]::-moz-range-track {
      width: 100%;
      height: 10px;
      cursor: pointer;
      background:rgba(255,255,255,0.1);
      border-radius: 5px;
    }
    input[type=range]::-moz-range-thumb {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background: #FFFFFF;
      cursor: pointer;
      box-shadow:0 0 5px rgba(0,0,0,0.5);

    }

    .value{
      width:50px;
    }

  }

}