.m-customfont{
  position:relative;
  z-index:90;
  display:flex;
  align-items: center;
  justify-content: space-between;

  .e-selector{
    .expanded {
      & > button{
        border-radius: 5px 5px 0 0!important;
      }
    }
    & > div{
      box-shadow:none;

      .dropdown-button{
        background:rgba(255,255,255,0.1)!important;
        border-radius:5px;
        box-shadow:none;

        .dropdown-icon.finished::before{
          border-top-color: #FFF;
        }
      }
      .font-list{
        background:#333!important;
        border-radius: 0 0 10px 10px;
        scrollbar-width: thin;
        scrollbar-color: #ffffff #000000;

        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-track {
          background:rgba(0,0,0,0.4)    ;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ffffff;
          border-radius: 10px;
          border: 3px solid #000000;
        }

        button{
          &:hover{
            background:#fff;
            color:#000;
          }
        }
      }
    }
  }
}