.m-colourpicker{
  display:flex;
  align-items: center;
  justify-content: space-between;
  min-height:34px;
  z-index:20;
  position:relative;

  .offclick{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:5;
  }

  .selector{
    display:flex;
    align-items: center;
    //border-left:1px solid rgba(255,255,255,0.1);
    margin-left:20px;
    padding-left:20px;
  }
  .e-reset{
    width:15px;
    height:15px;

    &:hover{
      cursor:pointer;

      svg{
        opacity:0.5;
      }
    }

    svg{
      width:15px;
      fill:#FFF;
      transition:all .3s ease-in-out;
    }
  }
  .e-selectedcolour{
    padding:5px 10px;
    border-radius:5px;
    background:rgba(255,255,255,0.1);
    margin:0 0 0 5px;
    display:inline-block;
    position:relative;
    min-width:110px;

    .current{
      display:flex;
      align-items: center;
      user-select: none;

      &:hover{
        cursor: pointer;
      }

      .icon {
        width:20px;
        height:20px;
        border-radius:5px;
        margin-right:3px;
        position:relative;
        overflow: hidden;

        &[style="background: transparent;"]{
          background:#FFF!important;

          &:before{
            content:'';
            display:block;
            width:150%;
            height:2px;
            background:#FF0000;
            position:absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%) rotate(-45deg);

          }
        }
      }
    }
    .popup{
      position:absolute;
      top:100%;
      z-index:20;

      .block-picker{
        & > div{
          &:last-child{
            span:first-child{
              div{
                border:1px solid #ddd;
              }
            }
          }
        }
      }
    }
  }
}