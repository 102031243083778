@import "normalize-css";
@import "references/mixins";
@import "references/typography";
@import "references/variables";
@import "minigrid";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes copied {
  0% {
    opacity: 0;
    transform: translateX(0%);
  }
  50% {
    opacity: 1;
    transform: translateX(-20%);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%);

  }
}

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

body {
  @include font-size(1.6, 2.4);
  background-image:url('https://chatstyler.colinhorn.co.uk/images/background.png');
  background-position: center;
  background-size: cover;
  background-color:#111111;
  height: 100vh;
  color: $c-white;
  font-family: $f-body;
  padding: 0;
}

footer {
  padding:5px 0;
  margin-top:30px;
  text-align: center;
  border-top:1px solid rgba(255,255,255,0.1);
  margin-top:auto;
  text-align: center;

  .container{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media(min-width:500px){
      flex-direction: row;
      justify-content: space-between;
    }
  }

  ul {
    list-style: none;
    margin: 5px 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      margin: 0 8px;

      a {
        display: block;

        &:hover {
          svg {
            fill: rgba(255, 255, 255, 0.6);
          }
        }

        svg {
          fill: $c-white;
          transition: all .3s ease-in-out;
          display:block;
        }
      }
    }
  }
}



h1, h2, h3, h4 {
  font-family: $f-title;
  margin: 0 0 10px;
  font-weight:400;
}

h1, h2, h3, p {
  color: $c-white;

  &.preamble {
    @include font-size(2.6, 3.2);
  }
}

h1 {
  font-size: clamp(40px, calc(2.5rem + ((1vw - 0px) * 2.0833)), 80px);
  line-height: clamp(40px, calc(2.5rem + ((1vw - 0px) * 2.0833)), 80px);
  min-height: 0;
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase;
  font-weight: 700;
  margin: 20px 0 8px;
  display: block;

  &:first-child {
    margin-top: 0;
  }

  & + .form-row {
    margin-top: 5px !important;
  }
}

img{
  max-width:100%;
}

.button{
  padding:10px 30px;
  border-radius:5px;
  text-decoration: none;
  background:#00A1BE;
  color:$c-white;
  font-weight:600;
  text-transform: uppercase;
  margin-top:5px;
  display:inline-flex;
  align-items: center;
  box-shadow:0 2px 2px rgba(0,0,0,0);
  transition:all .3s ease-in-out;

  svg{
    width:15px;
    height:15px;
    margin-right:5px;
    fill:$c-white;
    display:block;
  }

  &:hover{
    background:darken(#00A1BE, 5%);
    transform:translateY(-2px);
    box-shadow:0 6px 12px rgba(0,0,0,0.3);
  }
}

.l-intro {
  width: 100%;
  text-align: center;
  padding: 5vh 0 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  //height: 80vh;

  p {
    max-width: 800px;
    margin:10px auto;
  }
}

.l-left {
  @include grid(12, 12, 5.5, 5, 5);

  .e-form {
    .e-title, label {
      @include font-size(1.8, 2.6);
    }

    .form-row {
      margin: 20px 0;
      padding: 20px;
      background: rgba(255,255,255,0.05);
      border-radius: 5px;
      border:1px solid rgba(255,255,255,0.15);

      & > div {
        margin-top: 5px;

        &:first-child {
          margin-top: 0;
        }
      }

      &:first-child {
        margin-top: 0;
      }

    }

    .toggle {
      position: relative;
      margin-top: 10px !important;
      display: block;
      width: 100%;

      &:first-child {
        margin-top: 0 !important;
      }

      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;

        & + label {
          align-items: center;
          margin: 0;
          position: relative;
          display: inline-flex;
          width: 180px;

          &:hover {
            cursor: pointer;
          }

          &:before, &:after {
            content: '';
            display: block;
          }

          &:before {
            position: absolute;
            right: 28px;
            top: 4px;
            height: 20px;
            width: 20px;
            background: $c-white;
            border-radius: 50%;
            z-index: 20;
            transition: all .3s ease-in-out;

          }

          &:after {
            position: absolute;
            border: 1px solid rgba(0, 0, 0, 0.4);
            border-radius: 40px;
            right: 0;
            width: 50px;
            height: 25px;
            transition: all .3s ease-in-out;
            background: rgba(184, 0, 0, 1);
            margin-left: 10px;
          }
        }

        &:checked {
          + label {
            &:before {
              right: 4px;
            }

            &:after {
              background: rgba(0, 208, 132, 1);
            }
          }
        }
      }
    }
  }
}

.l-right {
  @include grid(12, 12, 6.5, 7, 7, 7);
}

.l-examples {
  @include grid(12, 12, 12, 12, 12);
  display: flex;
  flex-direction: row;

  .box {
    width: 100%;
    display: block;
    margin-bottom: 0px;
    margin-right: 10px;

    & + .box {
      margin-right: 0;
      margin-left: 10px;
    }

    .inner {
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    }

    &.light {
      .inner {
        background: $c-white;

        .chat-line {
          .message {
            color: var(--chatColour, #444);
          }
        }
      }
    }

    &.dark {
      .inner {
        background: #18181B;
        width: 100%;

        .chat-line {
          .message {
            color: var(--chatColour, #444);
          }

        }
      }
    }
  }
}

.l-buttons {
  @include grid(12, 12, 12, 12, 12);
  margin-top: 30px;

  button {
    @include font-size(2, 3);
    width: 100%;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 10px 15px;
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: $c-white;
    border: none;
    position: relative;
    transition: all .3s ease-in-out;

    &.streamelements {
      background: #5088C7;

      .e-copy {
        background: darken(#5088C7, 35%);
      }

      &:hover {
        background: darken(#5088C7, 25%);
      }
    }

    &.streamlabs {
      background: darken(#80F5D2, 35%);

      .e-copy {
        background: darken(#80F5D2, 50%);
      }

      &:hover {
        background: darken(#80F5D2, 45%);
      }
    }

    .e-copy {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(0, 0, 0, 0.5);
      width: 30px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.copied {
        svg {
          transform: scale(0.8);
        }

        &:before {
          @include font-size(1.4, 1.6);
          color: $c-white;
          content: 'Copied';
          position: absolute;
          right: calc(100% + 5px);
          transform: translateX(0%);
          opacity: 0;
          animation: copied 2s ease-in-out forwards;
          z-index: -1;
        }
      }

      svg {
        width: 20px;
        height: 20px;
        fill: $c-white;
        transition: all .3s ease-in-out;
        transform-origin: center;
      }
    }

    img {
      width: 40px;
      margin-right: 15px;
    }

    & + button {
      margin-top: 20px;
    }
  }

}

.l-code {
  @include grid(12, 12, 12, 12, 12);
  margin-top: 0;

  .inner {
    @include font-size(1.3, 1.7);
    background: #111;
    border-radius: 0 0 10px 10px;
    color: #555;
    font-family: Verdana, serif;
    padding: 15px;
    width: 100%;

    h4 {
      font-family: $f-body;
    }

    code {
      display: block;
      white-space: pre-line;
    }
  }
}

//defaultStyles
.twitch-chat{
  perspective:1000px;
  display:flex;
  flex-direction: column;
  align-items: flex-end;
}
.chat-line {
  margin: var(--margins, 10px) 0;
  display: flex;
  flex-wrap: wrap;
  animation: var(--animation, none) 0.5s ease-in-out, var(--fade, none) 0.5s ease-in-out forwards;
  opacity: var(--opacity, 1);
  transform-style: preserve-3d;
  transform:rotateZ(var(--zPerspective, 0deg)) rotateX(var(--xPerspective, 0deg)) rotateY(var(--yPerspective, 0deg));

  &:last-child {
    animation-delay: 0.5s;
  }
}

.chat-line .badges {
  display: var(--badgeCheck, inline-flex);
  align-items: center;
  margin-right: 5px;
}

.chat-line .badge {
  height: 1em;
  margin: 0 2px;
  vertical-align: text-top
}

.chat-line .username {
  font-family: var(--userFont, "Roboto");
  font-weight: var(--userWeight, 700);
  font-size: var(--userFontSize, inherit);
  line-height: calc(var(--userFontSize) * 1.2);
  border-radius: var(--userBorderRadius, 0);
  color: var(--userColor, #ff0000);
  background: var(--userBackground, transparent);
  padding: calc(var(--userPadding) * 0.4) var(--userPadding);
  z-index: 20;
}

.chat-line .message {
  font-family: var(--chatFont, "Roboto");
  font-weight: var(--chatWeight, 400);
  font-size: var(--chatFontSize, inherit);
  line-height: calc(var(--chatFontSize) * 1.2);
  border-radius: var(--chatBorderRadius, 0);
  background: var(--chatBackground, transparent);
  padding: calc(var(--chatPadding) * 0.8) var(--chatPadding, 0);
  display: block;
  margin-top: var(--spacing, 0);
  width: 100%;
}

.chat-line:not(.action) .colon:after {
  display: none;
}

//Support Block
.m-support{
  padding:20px 0 0;
  .wrapper{
    position:relative;
    height:250px;
    width:100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    border-radius:10px;

    // &:before{
    //   content:'Support Me';
    //   display:block;
    //   padding:2px 5px;
    //   border-radius:5px;
    //   background:rgba(0,0,0,0.5);
    //   color:#FFFFFF;
    //   position:absolute;
    //   top:5px;
    //   right:5px;
    //   z-index:20;
    //   text-transform: uppercase;
    //   font-size:12px;
    // }

  }
  video{
    margin:0 auto;
    display:block;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    
  }

}