/*****
Reference to SMACSS - http://smacss.com/

NAMING CONVENTIONS:
c- : Color,
l- : Layout,
m- : Module,
h- : Header
n- : Navigation
f- : Footer,
a- : Aside
e- : Element,
*****/

//COLOURS
$c-primary:#23408f;
$c-secondary: #7cc245;

$c-title: #142143;
$c-text: #1f1e1e;
$c-link: $c-primary;

$c-white: #ffffff;
$c-black: #000000;

$c-lightgrey: #efefef;
$c-mediumgrey: #888888;
$c-darkgrey: #111111;

$c-border: #dfdfdf;
$c-error:#c50600;
$c-errorbg: #ffdcdc;



//FONTS
$f-title: "Bebas Neue", sans-serif;
$f-body: "Roboto", sans-serif;